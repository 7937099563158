@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";
@import "../../node_modules/magnific-popup/dist/magnific-popup";

// BACKGROUNDS
#newsletter {
	background-color: $color-pale-nimbus;
}

#listen {
	// background-color: $color-white;
}

#tour {
	background-image: url('../img/sephschlueter2024/bg/bg-tour.jpg');
}

#watch {
	// background-color: $color-white;
}

#about {}

#follow {
	// background-color: $color-white;
}

#shop {}

footer {}



html {
	background: $color-white;
	font-family: "source-serif-pro", serif;
	font-size: 16px;
	line-height: 1;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-navy-blue;
	position: relative;

	.all-bg {
		position: fixed;
		inset: 0;
		z-index: -1;
		width: 100%;
		height: 100vh;
		object-fit: cover;
		object-position: center;
	}
}

a {
	color: $color-navy-blue;

	@include hover-focus {
		opacity: .6;
	}
}

.btn {
	display: inline-block;
	font-size: rems(15);
	min-width: rems(199);
	padding: rems(15) rems(10);
	text-align: center;
	vertical-align: middle;

	&.btn-light {
		background: $color-soft-silver;
		color: $color-navy-blue;

		@include hover-focus {
			opacity: .6;
		}
	}

	&.btn-dark {
		background: $color-navy-blue;
		color: $color-pale-nimbus;

		@include hover-focus {
			opacity: .6;
		}
	}
}

header {
	position: relative;

	>nav {
		left: 0;
		position: absolute;
		right: 0;
		top: 0;

		.container {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			@include phone-down {
				justify-content: center;
			}

			>h1 {
				margin: 0;
				width: rems(400);

				img {
					filter: brightness(0) invert(1)
				}
			}

			>.links {
				text-align: right;

				@include phone-down {
					display: none;
				}

				>ul {
					display: inline-block;
					list-style: none;
					margin: 0;
					padding: 0;
					vertical-align: middle;

					>li {
						display: inline-block;
						font-size: rems(16);
						vertical-align: middle;

						>a {
							font-style: italic;
							color: $color-white;

							@include hover-focus {
								color: $color-black;
							}
						}
					}

					&.menu {
						>li {
							font-style: iatlic;

							+li {
								margin-left: rems(20);
							}
						}
					}

					&.socials {
						margin-left: rems(20);

						>li {
							+li {
								margin-left: rems(5);
							}
						}
					}
				}
			}
		}
	}

	>img {
		width: 100%;
		height: 100vh;
		object-fit: cover;
	}
}


main {

	.section {
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		padding: rems(150) 0;

		@include tablet-down {
			padding: rems(100) 0;
		}

		@include phone-down {
			padding: rems(50) 0;
		}

		.section-title {
			font-size: rems(40);
			font-style: italic;
			font-weight: 400;
			margin: 0 0 rems(25) 0;
		}

		.more {
			text-align: center;
		}

		&#newsletter {

			.container {
				display: flex;


				@include tablet-down {
					flex-direction: column;
				}

				.promo {
					flex: .3;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;

					@include tablet-down {
						margin-bottom: rems(48);
					}

					p {
						font-size: rems(30);
						letter-spacing: 4px;
						text-align: center;
						margin-bottom: rems(48);
					}

					.cover {
						margin-bottom: rems(30);
						max-width: rems(356);
					}

					a {
						background-color: $color-navy-blue;
						color: $color-pale-nimbus;
						padding: rems(16) rems(50);
						font-size: rems(20);
						font-weight: 300;
						letter-spacing: 2px;

						@include hover-focus {
							opacity: .5;
						}
					}
				}

				.form-wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					flex: .7;

					p {
						font-family: $font-source-serif;
						font-size: rems(30);
						font-style: italic;
						font-weight: 300;
						text-transform: none;
						text-align: center;
					}

					h2 {
						font-family: $font-source-serif;
						font-size: rems(30);
						font-weight: 400;
						letter-spacing: 4px;
						text-transform: uppercase;
						margin-bottom: rems(80);
						text-align: center;
					}

					form {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						width: 100%;
						max-width: rems(420);

						input[type="email"] {
							width: 100%;
							background-color: $color-pale-nimbus;
							color: $color-navy-blue;
							border: none;
							border-bottom: 1px solid $color-navy-blue;
							margin-bottom: rems(50);
						}

						input[type="email"]::placeholder {
							color: $color-navy-blue;
						}

						button {
							background-color: $color-navy-blue;
							color: $color-pale-nimbus;
							padding: rems(10) rems(20);
							font-size: rems(20);
							font-weight: 300;
							letter-spacing: 2px;

							@include hover-focus {
								opacity: .5;
							}
						}

					}
				}
			}
		}

		&#tour {
			h2 {
				color: $color-pale-nimbus;
			}

			.section-content {
				overflow-y: scroll;



				#events {
					margin-top: rems(70);
					width: 100%;
					max-width: 55%;
					// height: rems(600);
					overflow-y: scroll;
					padding-right: rems(20);

					@include tablet-down {
						max-width: none;
					}

					.event {
						gap: rems(12);
						display: flex;
						font-size: rems(18);
						line-height: 1.3;
						font-style: italic;
						text-transform: none;
						margin-bottom: rems(30);
						color: $color-pale-nimbus;

						@include tablet-down {
							align-items: center;
							flex-direction: column;
							text-align: center;
						}

						.event-date {
							flex: .7;
						}

						.event-info {
							flex: 1.2;
						}

						>.event-links {
							flex: .3;
						}

						.btn {
							text-transform: uppercase;
							font-style: normal;
							display: block;
							letter-spacing: 2px;
							padding: rems(10) rems(20);
							min-width: 0;
						}
					}
				}
			}

			.tour-btn {
				margin: rems(60) auto 0;
				width: max-content;
				display: block;
			}
		}

		&#listen {
			.section-content {
				.slider-music {
					position: relative;
					padding: 0 30px;

					.slick-arrow {
						position: absolute;
						top: calc(50% - 15px);
						font-size: 30px;
						background-color: transparent;
						color: $color-navy-blue;
						border: none;
						padding: 0;
						cursor: pointer;
						z-index: 99;

						&.slick-prev {
							left: 0;
						}

						&.slick-next {
							right: 0;
						}
					}

					.music-item {
						align-items: center;
						display: flex !important;
						flex-direction: row;
						flex-wrap: nowrap;
						text-align: center;
						gap: 30px;

						@include tablet-down {
							flex-direction: column;
						}

						.music-image {
							flex: 0 0 40%;

							img {
								margin: 0 auto;
							}
						}

						.music-info {
							flex: 0 0 55%;
							padding-left: rems(30);

							@include tablet-down {
								margin-top: rems(30);
								padding-left: 0;
							}

							>span {
								display: block;
								font-size: rems(15);
							}

							>img {
								margin: 0 auto;
								filter: grayscale(100%) contrast(0%);

								&:first-of-type {
									width: rems(580);
								}

								&:last-of-type {
									margin-bottom: rems(20);
									width: rems(310);
								}
							}

							.music-title {
								margin-bottom: 30px;
							}

							p.title {
								font-size: rems(32);
								margin-bottom: rems(24);
								margin-top: rems(24);
							}

							.btn {
								margin-bottom: rems(20);
							}
						}
					}
				}

			}
		}

		&#about {

			.section-title {
			}

			.section-content {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				@include tablet-down {
					display: block;
				}

				>div {
					&:nth-child(1) {
						flex: 0 0 45%;

						@include tablet-down {
							img {
								margin: 0 auto;
							}
						}

					}

					&:nth-child(2) {
						color: #272727;
						flex: 0 0 55%;
						font-size: rems(16);
						font-style: italic;
						line-height: line-height(19, 15);
						padding: 0 rems(20);
						text-transform: none;
						max-height: rems(600);
						overflow-y: scroll;

						@include tablet-down {
							margin-top: rems(30);
							padding: 0;
						}

						p {
							margin: 0;

							+p {
								margin-top: rems(10);
							}
						}
					}
				}
			}
		}

		&#watch {

			.section-content {
				padding: 0 rems(48);
			}

			.embed-container {
				margin-bottom: rems(24);
			}

			.video-title {
				font-size: rems(20);
			}

			// #video_player {
			// 	margin-bottom: rems(25);

			// 	span {
			// 		display: block;
			// 		font-size: rems(15);
			// 		margin-top: rems(15);
			// 	}
			// }
		}

		&#follow {


			.section-content {
				display: block;
				// margin-top: rems(100);
			}

			#instagram_posts {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				>div {
					flex: 0 0 25%;
					padding: 1px;

					@include phone-down {
						flex: 0 0 50%;
					}

					>a {
						display: block;
						height: 0;
						opacity: 1;
						padding-bottom: 100%;
						position: relative;

						>img {
							bottom: 0;
							height: 100%;
							left: 0;
							object-fit: cover;
							object-position: center top;
							position: absolute;
							right: 0;
							top: 0;
							width: 100%;
						}

						@include hover-focus {
							opacity: 0.8;
						}
					}
				}
			}
		}

		&#shop {
			.merch {
				display: flex;
				flex-direction: row;
				justify-content: center;
				flex-wrap: nowrap;
				margin: 0 -15px;
				text-align: center;

				@include phone-down {
					display: block;
					margin: 0;
				}

				>div {
					flex: 0 0 33.333333%;
					margin-bottom: rems(30);
					padding: 0 15px;

					@include phone-down {
						padding: 0;
					}

					>a {
						display: block;
						opacity: 1;

						@include hover-focus {
							opacity: 0.8;
						}
					}
				}
			}

			.more {}
		}
	}
}

footer {
	text-align: center;
	margin-bottom: rems(100);

	.container {
		>img {
			margin: 0 auto;
			width: rems(450);
		}

		.socials {
			margin: rems(40) 0;
			padding: 0;

			>li {
				display: inline-block;
				font-size: rems(33);
				vertical-align: middle;

				+li {
					margin-left: rems(20);
				}

				>a {
					color: $color-navy-blue;
					opacity: 1;

					@include hover-focus {
						color: $color-navy-blue;
						opacity: 0.5;
					}
				}
			}
		}

		.contact {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 0 auto rems(80) auto;
			max-width: rems(450);
			color: $color-navy-blue;

			@include phone-down {
				display: block;
			}

			>div {
				>span {
					display: block;
					font-size: rems(20);
					font-style: italic;
					margin-bottom: rems(15);
				}

				@include phone-down {
					+div {
						margin-top: rems(30);
					}
				}
			}
		}

		.logo {
			margin-bottom: rems(30);

			>a {
				display: inline-block;
				max-width: rems(100);
				opacity: 1;
				vertical-align: middle;

				@include hover-focus {
					opacity: 0.8;
				}
			}
		}

		.copyright {
			font-size: rems(14);
			line-height: line-height(17, 14);
			color: $color-navy-blue;

			p {
				margin: 0;

				a {
					color: $color-navy-blue;

					@include hover-focus {
						color: $color-navy-blue;
						opacity: .5;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.owl-carousel {
	position: relative;

	.owl-nav {
		button {
			position: absolute;
			top: calc(30% - 15px);

			&.owl-prev {
				left: -40px;
			}

			&.owl-next {
				right: -40px;
			}

			span {
				color: $color-navy-blue;
				font-size: 120px;
			}
		}
	}
}

.mfp-content {
	padding: rems(100);

	@include phone-down {
		padding: rems(50);
	}

	#newsletter-popup {
		margin: rems(200);
		background-color: $color-pale-nimbus;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: rems(1350);
		padding: rems(40);
		margin: 0 auto;
		position: relative;

		@media screen and (max-width: 1100px) {
			flex-direction: column;
			gap: rems(40);
		}

		.left {
			flex: .8;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			max-width: rems(600);
			text-align: center;

			img.logo {
				width: 100%;
				max-width: rems(600);
			}

			p {
				text-transform: uppercase;
				font-size: rems(24);
				letter-spacing: 5px;
				margin-top: rems(-20);
				margin-bottom: rems(30);
			}

			.cover {
				width: 100%;
				max-width: rems(350);
				margin-bottom: rems(30);
			}

			a.btn {}
		}

		.right {
			flex: 1.2;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;

			p {
				text-transform: capitalize;
				font-size: rems(30);
				font-weight: 300;
				font-style: italic;
			}

			h2 {
				font-size: rems(32);
				letter-spacing: 2px;
				margin-bottom: rems(75);
				font-weight: 400;
			}

			form {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				width: 100%;
				max-width: rems(420);

				input[type="email"] {
					width: 100%;
					background-color: $color-pale-nimbus;
					color: $color-navy-blue;
					border: none;
					border-bottom: 1px solid $color-navy-blue;
					margin-bottom: rems(50);
				}

				input[type="email"]::placeholder {
					color: $color-navy-blue;
					opacity: .5;
				}

				button {
					background-color: $color-navy-blue;
					color: $color-pale-nimbus;
					padding: rems(10) rems(20);
					font-size: rems(20);
					font-weight: 300;
					letter-spacing: 2px;

					@include hover-focus {
						opacity: .5;
					}
				}

			}
		}

		.mfp-close {
			color: $color-almond;
		}
	}
}