$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-off-black: #202020;
$color-gray: #DADADA;
$color-davy-grey: #555555;
$color-rifle-green: #40432E;
$color-beaver: #A5846A;
$color-almond: #ECDCC5;
$color-crocodile: #70745E;
$color-navy-blue: #08264A;
$color-pale-nimbus: #F8F8F2;
$color-soft-silver: #FAF8E8;


$font-source-serif: 'source-serif-pro'
